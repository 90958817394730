<template>
  <div>
    <!-- /.container-fluid -->
    <img src="../../assets/img/assetHome.png" style="width: 100%;">
  </div>
</template>
<script>
const { console } = window;
import axios from "axios";
import "@assets/scss/pages/dashboard.scss"

export default {
  data() {
    return {
   
    };
  },
  mounted() {
  },
  methods: {
  
  },
};
</script>
